import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Honyak', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Nicholas Honyak',
  subtitle: "I'm a Full-stack Developer",
  cta: "Here's me",
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'A detail-oriented communicator with robust technical and programming skills. Seven years of professional experience across finance, real estate, and nonprofit sectors.',
  paragraphTwo: true,
  paragraphThree: true,
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  
  {
    id: nanoid(),
    img: 'User-places ss.png',
    title: 'User Places',
    info: 'User is able to upload a photo with location tag, edit existing photos, and delete photos.',
    info2: 'React.js, Express.js, Node.js, MongoDB, Google Maps API',
    url: 'https://honyak.com/user-places',
    repo: 'https://github.com/honyak/user-places', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Weather-pro ss.png',
    title: 'Weather Pro',
    info: 'Provides real-time temperature, conditions, and forecast every 3 hours.',
    info2: 'React.js, Material UI, OpenWeather API, Google Geocode API',
    url: 'https://honyak.com/weather-pro',
    repo: 'https://github.com/honyak/weather-react', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'reading-list ss.png',
    title: 'Reading List App',
    info: 'User can populate a reading list with Book titles and descriptions, which is written to the database for persistence.',
    info2: 'React.js, React Router, Express.js, Node.js, MongoDB',
    url: 'https://honyak.com/reading-list',
    repo: 'https://github.com/honyak/ReadingList', // if no repo, the button will not show up
  }
];

// CONTACT DATA
export const contactData = {
  cta: "Let 's work together",
  btn: 'Send me an email',
  email: 'nicholas@honyak.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/honyak',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/honyak',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
